<template>
  <div>
    <b-row>
        <b-col md="5" lg="4">
            <b-card bg-variant="Info">
                <h4>{{ infomodal.rag_soc }}</h4>
                <p>{{ infomodal.indirizzo }}<br />{{ infomodal.cap }} {{ infomodal.localita }} ({{ infomodal.provincia }})<br />{{ infomodal.paese }}</p>
            </b-card>

            <b-card bg-variant="Info" title="Info Attivazione Nuovo Utente">
                <p>Il CRM <u>non</u> invia la notifica di attivazione dell'utente pertanto è necessario comunicare le credenziali al cliente, nello specifico:</p>
                <ul>
                    <li>Username</li>
                    <li>Password</li>
                    <li>Link per collegarsi al CRM</li>
                </ul> 
            </b-card>

        </b-col>
        <b-col md="7" lg="8">   
            <b-card bg-variant="Success" class="text-center" v-if="submitted">
            <b-button
            variant="outline-success"
            :to="{ name: 'crm-aziende' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Lista Aziende
            </b-button>
            </b-card>

            <b-card bg-variant="Default" title="Attivazione Nuovo Utente" v-if="Caricato">

            <validation-observer ref="simpleRules">
                <b-form>
                    <b-form-checkbox
                        checked="true"
                        name="attivo"
                        v-model="campiform.attivo"
                        switch
                        class="mb-2"
                        >
                        Attiva Utente
                    </b-form-checkbox>

                    <b-form-group label="Tipo di Utente" label-for="id_tipo">
                        <validation-provider
                        #default="{ errors }"
                        name="Tipo di Utente"
                        rules="required"
                        >
                        <b-form-select
                            v-model="campiform.selected"
                            @change="onChange($event)"
                            :options="options"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <div class="mb-2" v-if="area_select_notifica">
                        <b-alert
                        variant="danger"
                        show
                        >
                            <h4 class="alert-heading"><feather-icon
                                icon="ArrowUpIcon"
                                class="mr-1"
                                size="21"
                            /> Attenzione !!!</h4>
                            <div class="alert-body">Attivando un Utente di tipo ADMIN, questo utente potrà vedere tutte le infomazioni di tutte le aziende</div>
                        </b-alert>
                    </div>

                    <b-form-group label="Nome" label-for="nome">
                        <validation-provider
                        #default="{ errors }"
                        name="Nome o Nome e Cognome dell'Utente"
                        rules="required"
                        >
                        <b-form-input
                            v-model="campiform.nome"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="Username" label-for="username">
                        <validation-provider
                        #default="{ errors }"
                        name="Username dell'utente per effettuare login"
                        rules="required|alpha-dash"
                        >
                        <b-form-input
                            v-model="campiform.username"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <div class="text-info">Consiglio: combina parte del nome personale e della ragione sociale dell'azienda, es. leo_biomed</div>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group label="password" label-for="password">
                        <validation-provider
                        #default="{ errors }"
                        name="Password dell'utente per effettuare login"
                        rules="required|min:8|alpha-dash"
                        >
                        <b-form-input
                            v-model="campiform.password"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <div class="text-info">Lunghezza minima 8 caratteri, caratteri alpha-numerici senza spazi</div>
                        </validation-provider>
                    </b-form-group>
                    
                    <b-form-group label="Email" label-for="email">
                        <validation-provider
                        #default="{ errors }"
                        name="Email dell'utente"
                        rules="required|email"
                        >
                        <b-form-input
                            v-model="campiform.email"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    
                    <b-form-group label="Recapito telefonico principale" label-for="telefono">
                        <b-form-input
                            v-model="campiform.telefono"
                            placeholder=""
                        />
                    </b-form-group>
                    
                    <b-form-group label="Recapito telefonico secondario" label-for="telefono2">
                        <b-form-input
                            v-model="campiform.telefono2"
                            placeholder=""
                        />
                    </b-form-group>

                <b-row>
                    <!-- submit button -->
                    <b-col md="12" class="text-center">
                    <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm(userData.id,infomodal.id)"
                    >
                        Crea Nuovo utente
                    </b-button>
                    </b-col>
                </b-row>
                </b-form>
            </validation-observer>

            </b-card>

        </b-col>
    </b-row>

  </div>
</template>

<script>
import router from '@/router'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BCard, BInputGroup, BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BFormSelect, BFormTextarea, BSpinner, BAlert, 
BFormCheckbox  } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, alphaDash
} from '@validations'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormTextarea,
    BSpinner,
    BAlert,
    BFormCheckbox,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      campiform: {
        attivo: true,
        nome: '',
        username: '',
        password: '',
        email: '',
        telefono: '',
        telefono2: '',
        selected: '1',
      },
      required,
      email,
      alphaDash,
      options: [ ],
      Caricato: false,
      submitted: false,
      userData: {},
      area_select_notifica: false,
      infomodal: {
        id: '',
        rag_soc: '',
        indirizzo: '',
        cap: '',
        localita: '',
        provincia: '',
        paese: '',
      },
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem('userData'))

    console.log(this.userData);

    this.$http.get('v2/crmadmin/singola_azienda/'+router.currentRoute.params.id_azienda)
    .then(response => {
        if(response.data.statusCode===200){
            this.infomodal = { id: response.data.dati.id,
                rag_soc: response.data.dati.rag_soc,
                indirizzo: response.data.dati.indirizzo,
                cap: response.data.dati.cap,
                localita: response.data.dati.localita,
                provincia: response.data.dati.provincia,
                paese: response.data.dati.paese };
        }
    })

    this.$http.get('v2/crmadmin/tipi_utente/')
    .then(response => {
        if(response.status===200){
            this.options = response.data.dati_risposta

            //seleziono nel select la prima voce restiuita (Utente CLIENTE)
            this.campiform.selected = response.data.dati_risposta[0].value
        } else {
            this.showToast('danger','AlertCircleIcon','Errore nel caricamento dei Tipi di Utente, prego contattare la nostra assistenza','','top-center')
        }

        this.Caricato = true;
    })
  },
  methods: {
    onChange(event) {
        //console.log("speriamo bene ... "+this.campiform.selected)

        //in caso di selezione "Utente ADMIN"
        if(this.campiform.selected == 2){
            this.area_select_notifica = true
        } else {
            //nascondi area
            this.area_select_notifica = false
        }
    },
    validationForm(id_utente_che_fa_operazione,id_azienda) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          console.log('start invio form ... spriamo bene')

          //aggiungi ID_azienda al post in partenza
          this.campiform = Object.assign({}, this.campiform, {
              id_utente_admin: parseInt(id_utente_che_fa_operazione), 
              id_azienda: parseInt(id_azienda)
            });

          //console.log(this.campiform)          
          
          this.$http.post('v2/crmadmin/salvanuovoutente', {
            dati_form: this.campiform 
            }).then(response => { 
              console.log("risposta server --------")
              console.log(response.data)

              if(response.data.statusCode===200){
                //risposta positiva
                if(response.data.dati_risposta.status == 'ok'){
                  //redirect verso dettaglio lead appena salvato
                  this.$router.replace('/crm/utenti_azienda/'+id_azienda)
                    .then(() => {
                      this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                    })
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.campiform = {}; //svuota i campi del form
                  this.showToast('success','CheckCircleIcon',response.data.success.descrizione,'','top-center')
                  this.submitted = true;
                }
                
              } else {
                //risposta negativa (errore sul server)
                this.showToast('danger','AlertCircleIcon',response.data.error.descrizione,'','top-center')
                this.submitted = false;
              }
            }).catch(e => {
              console.log(e);
            });
          
        }
      })
    },
    showToast(variant, icona, titolo, testo, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titolo,
          icon: icona,
          text: testo,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>
